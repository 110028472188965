<form #form="ngForm">

  <div *ngIf="value?.implementation.code === 'CLICK' || value?.implementation.code === 'LICENSE_INSTANCE'"
    [ngClass]="{'d-flex align-items-center': viewMode === 'inline'}">
    <mat-form-field
      [ngClass]="{'w-100': viewMode === 'normal'}">
      <mat-label>Valore di partenza</mat-label>
      <input matInput
        type="number"
        placeholder="Valore di partenza"
        name="min"
        [(ngModel)]="value.min"
        #minModel="ngModel"
        [readonly]="!editMode"
        [min]="0"
        max="{{value.actual || value.max}}"
        validateMin
        [validateMinValue]="0"
        validateMax
        [validateMaxValue]="min(value.actual, value.max)"
        required>
      <mat-error appFormFieldError
        [formFieldModel]="minModel"
        [form]="actualForm"></mat-error>
    </mat-form-field>

    <mat-form-field
      [ngClass]="{'w-100': viewMode === 'normal', 'ml-3': viewMode === 'inline'}">
      <mat-label>Numero massimo</mat-label>
      <input matInput
        type="number"
        placeholder="Numero massimo"
        name="max"
        [(ngModel)]="value.max"
        #maxModel="ngModel"
        [readonly]="!editMode"
        validateMin
        [min]="1"
        [validateMinValue]="max(value.actual, value.min, 1)"
        validateMax
        [validateMaxValue]="999999999999"
        required>
      <mat-error appFormFieldError
        [formFieldModel]="maxModel"
        [form]="actualForm"></mat-error>
    </mat-form-field>

    <div [ngClass]="{'mt-3': viewMode === 'normal','ml-4': viewMode === 'inline'}"
      *ngIf="showActual">
      Valore attuale: {{value.actual}}
    </div>
  </div>

  <div *ngIf="value?.implementation.code === 'EXPIRATION'"
    [ngClass]="{'d-flex align-items-center': viewMode === 'inline'}">
    <mat-form-field
      [ngClass]="{'w-100': viewMode === 'normal'}">
      <mat-label>Data attivazione</mat-label>
      <input matInput
        [matDatepicker]="validFromPicker"
        name="validFrom"
        [(ngModel)]="value.validFrom"
        [readonly]="true"
        (click)="editMode && validFromPicker.open()">
      <mat-datepicker-toggle matSuffix
        [for]="validFromPicker"
        *ngIf="editMode"></mat-datepicker-toggle>
      <mat-datepicker #validFromPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field
      [ngClass]="{'w-100': viewMode === 'normal', 'ml-3': viewMode === 'inline'}">
      <mat-label>Data scadenza</mat-label>
      <input matInput
        [matDatepicker]="validToPicker"
        [(ngModel)]="value.validTo"
        name="validTo"
        [readonly]="true"
        (click)="editMode && validToPicker.open()">
      <mat-datepicker-toggle matSuffix
        [for]="validToPicker"
        *ngIf="editMode"></mat-datepicker-toggle>
      <mat-datepicker #validToPicker></mat-datepicker>
    </mat-form-field>
  </div>

  <ng-container *ngIf="value?.implementation.autoRenewSupport && editMode">
    <mat-checkbox [(ngModel)]="value.autoRenew"
      name="autoRenew"
      class="pt-3 pb-2"
      (change)="onAutoRenewChange()"
      [disabled]="!editMode">
      Auto rinnovo</mat-checkbox>

    <div *ngIf="value?.autoRenew"
      class="mt-3">
      <h3 class="m-0">Cadenza:</h3>

      <div class="row">
        <div class="col-24"
          [ngClass]="{'col-sm-12': periodicity?.code === 'custom'}">
          <mat-form-field
            class="w-100 pr-2">
            <mat-label>Peridiocità</mat-label>
            <mat-select [(ngModel)]="periodicity"
              #periodicityModel="ngModel"
              name="periodicity"
              required>
              <mat-option [value]="undefined">Seleziona</mat-option>
              <mat-option *ngFor="let periodicity of periodicities;"
                [value]="periodicity">
                {{periodicity.label}}
              </mat-option>
            </mat-select>
            <mat-error appFormFieldError
              [formFieldModel]="periodicityModel"
              [form]="actualForm"></mat-error>
          </mat-form-field>
        </div>

        <div class="col-24"
          [ngClass]="{'col-sm-12': periodicity?.code === 'custom'}">
          <mat-form-field
            class="w-100 pl-2"
            *ngIf="periodicity?.code === 'custom'">
            <input matInput
              type="number"
              placeholder="Giorni"
              class="text-right"
              [(ngModel)]="value.autoRenewExtension"
              #autoRenewExtensionModel="ngModel"
              min="1"
              name="autoRenewExtension"
              size="3"
              [validateMin]="true"
              [validateMinValue]="1"
              required>
            <span matSuffix>giorni</span>
            <mat-error appFormFieldError
              [formFieldModel]="autoRenewExtensionModel"
              [form]="actualForm"></mat-error>
          </mat-form-field>
        </div>
      </div>

    </div>
  </ng-container>

  <ng-container *ngIf="value?.autoRenew && !editMode">
    <div class="d-flex align-items-center mt-2">
      <mat-icon class="mr-2">schedule</mat-icon>
      <span class="font-weight-bold">Rinnovo Automatico
        (ogni {{value.autoRenewExtension}} giorni)
      </span>

      <span class="ml-1"> - Prossimo rinnovo: {{value.autoRenewEndOn | date:'short'}}</span>
    </div>
  </ng-container>
</form>