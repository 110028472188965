import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CustomerService} from 'app/armp/services/customer.service';
import {ActivatedRoute} from '@angular/router';
import {VoucherService} from 'app/armp/services/voucher.service';
import {
    VoucherStatusComponent
} from 'app/armp/voucher/voucher-edit/voucher-status/voucher-status/voucher-status.component';
import {TranslateService} from '@ngx-translate/core';

import {CertificateBindingService} from 'app/armp/services/certificate-binding.service';

import {PolicyService} from 'app/armp/services/policy.service';
import {AuthService} from 'app/armp/core/auth.service';
import {ErrorService} from 'app/armp/core/error.service';
import {ToastService} from 'app/armp/services/toast.service';
import {AutomaticUserBindingService} from 'app/armp/services/automaticuser-binding.service';
import {VoucherFull} from '../../models/voucherFull';

@Component({
    selector: 'app-voucher-edit',
    templateUrl: './voucher-edit.component.html',
    styleUrls: ['./voucher-edit.component.scss'],
    standalone: false
})
export class VoucherEditComponent implements OnInit, OnDestroy {
    customerExternalLink: any;
    orderExternalLink: any;

    automaticUser: any;
    automaticUserInitialValue: any;

    editMode = false;
    addMode = false;

    voucher: VoucherFull;

    certificates = [];

    private _isReadonly = false;
    @Input()
    set isReadonly(v: boolean) {
        this._isReadonly = v;
    }

    get isReadonly() {
        return this._isReadonly;
    }

    @ViewChild(VoucherStatusComponent, {static: true}) voucherStatus!: VoucherStatusComponent;

    constructor(
        public authService: AuthService,
        private customerService: CustomerService,
        private voucherService: VoucherService,
        private activatedRoute: ActivatedRoute,
        private toastService: ToastService,
        private translateService: TranslateService,
        private certificateBindingService: CertificateBindingService,
        private errorService: ErrorService,
        private policyService: PolicyService,
        private automaticUserBindingService: AutomaticUserBindingService
    ) {
        this.voucher = this.activatedRoute.snapshot.data['voucher'];
    }

    ngOnInit() {
        const currentUser = this.authService.user;
        if (!this.authService.hasRole('VOUCHER_CAN_WRITE')) {
            this._isReadonly = true;
        }

        this.customerService.getExternalLink(this.voucher.customerId).then((link) => {
            this.customerExternalLink = link;
        });

        this.voucherService.getOrderExternalLink(this.voucher.id, this.voucher.code).then((link) => {
            this.orderExternalLink = link;
        });
        this.loadCertificateBindings();
        this.loadAutomaticUser();
    }

    saveCertificates() {
        this.voucherService.saveCertificateBindings(this.voucher.id, this.certificates).then(() => {
            this.toastService.success({message: 'Certificati Aggiornati'});
        });
    }

    deactivate() {
        if (confirm(`Sicuro di volere disattivare questo Voucher?`)) {
            this.voucherService.setActive(this.voucher.id, false).then((voucher) => {
                this.voucher.active = voucher.active;
                this.toastService.success({message: 'Voucher disattivato'});
            });
        }
    }

    activate() {
        this.voucherService.setActive(this.voucher.id, true).then((voucher) => {
            this.voucher.active = voucher.active;
            this.toastService.success({message: 'Voucher attivato'});
        });
    }

    refreshVoucher() {
        this.loadCertificateBindings();
        this.voucherService.getById(this.voucher.id).then((voucher) => {
            /** getById does not have the same type */
            this.voucher = voucher as VoucherFull;
            this.voucherStatus.refresh();
        });
    }

    loadCertificateBindings() {
        this.certificateBindingService.getCertificatesByVoucherId(this.voucher.id).then((response) => {
            this.certificates = response;
            this.voucher.certificates = this.certificates;
        });
    }

    loadAutomaticUser() {
        if (this.voucher.automaticSignatureUserId === undefined || this.voucher.automaticSignatureUserId === null) {
            return;
        }
        this.automaticUserBindingService.getById(this.voucher.automaticSignatureUserId)
            .then((response) => {
                this.automaticUser = response;
                this.voucher.automaticUser = this.automaticUser;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    updateAutomaticUser() {
        if (this.addMode) {
            this.automaticUserBindingService
                .addUser(this.automaticUser)
                .then((newAutomaticUser: any) => {
                    this.addMode = false;
                    newAutomaticUser.delegatedPassword = null;
                    newAutomaticUser.pin = null;
                    this.automaticUser = newAutomaticUser;
                    this.voucher.automaticSignatureUserId = newAutomaticUser.id;
                    this.voucherService
                        .update(this.voucher)
                        .then((x) => {
                            this.toastService.success({message: 'Utenza associata'});
                            this.voucher.automaticUser = newAutomaticUser;
                        })
                        .catch((e) => {
                            this.toastService.error({message: `Errore durante l'associazione dell'utenza`});
                        });
                })
                .catch((e) => {
                    this.toastService.error({message: `Errore durante l'aggiunta dell'utenza`});
                });
        }
        if (this.editMode) {
            this.automaticUserBindingService
                .updateSecure(this.automaticUser)
                .then((x) => {
                    this.editMode = false;
                    this.automaticUser.delegatedPassword = null;
                    this.automaticUser.pin = null;
                    this.voucher.automaticSignatureUserId = this.automaticUser.id;
                    this.voucher.automaticUser = this.automaticUser;
                    this.toastService.success({message: 'Utenza aggiornata'});
                })
                .catch((e) => {
                    this.toastService.error({message: `Errore durante l'aggiornamento dell'utenza`});
                });
        }
    }

    addAutomaticUser() {
        this.addMode = true;
        this.automaticUser = {};
    }

    editAutomaticUser() {
        this.automaticUser.delegatedPassword = '';
        this.automaticUser.pin = '';
        this.editMode = true;
    }

    undoAutomaticUser() {
        if (this.editMode) {
            this.editMode = !this.editMode;
        }
        if (this.addMode) {
            this.addMode = !this.addMode;
            this.automaticUser = undefined;
        }
    }

    removeAutomaticUser() {
        if (!confirm(`Sicuro di volere rimuovere l'utenza di firma da questo Voucher?`)) {
            return;
        }
        this.voucher.automaticSignatureUserId = null;
        this.voucherService
            .update(this.voucher)
            .then((x) => {
                this.automaticUserBindingService
                    .delete(this.automaticUser.id)
                    .then((x) => {
                        this.automaticUser = undefined;
                        this.voucher.automaticUser = undefined;
                        this.editMode = false;
                        this.toastService.success({message: 'Utenza rimossa'});
                    })
                    .catch((e) => {
                        this.toastService.error({message: `Errore durante la rimozione dell'utenza`});
                    });
            })
            .catch((e) => {
                this.toastService.error({message: `Errore durante la rimozione dell'utenza`});
            });
    }

    ngOnDestroy(): void {
    }
}
