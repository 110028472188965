import { OnInit, Output, Input, EventEmitter, OnDestroy, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounce, filter, distinctUntilChanged } from 'rxjs/operators';
import { timer, Subscription } from 'rxjs';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
    selector: 'bitf-search',
    templateUrl: './bitf-search.component.html',
    styleUrls: ['./bitf-search.component.scss'],
    standalone: false
})
export class BitfSearchComponent implements OnInit, OnDestroy {
  @Input() hasSearchIcon = true;
  @Input() hasCancelButton = false;
  @Input() isInToolbar = false;
  @Input() placeholder = '';
  @Input() label = '';
  @Input() minLength = 2;
  @Input() disableAutoSearch = false;
  @Input() debounceTime = 500;
  @Input() formFieldClass: string = '';
  @Input() appearance: MatFormFieldAppearance = 'fill';

  @Output() valueChanges: EventEmitter<any> = new EventEmitter();

  formControl: UntypedFormControl = new UntypedFormControl('');
  subscription: Subscription = new Subscription();

  isFirstSearchDone = false;

  constructor() {}

  ngOnInit() {
    if (!this.disableAutoSearch) {
      this.subscription = this.formControl.valueChanges
        .pipe(
          debounce(() => timer(this.debounceTime)),
          distinctUntilChanged(),
          filter((value) => value.length >= this.minLength || (this.isFirstSearchDone && value.length === 0))
        )
        .subscribe((value) => {
          this.isFirstSearchDone = true;
          this.valueChanges.emit(value);
        });
    } else {
      // TODO this.valueChanges.emit(value); on entery keypressed
    }
  }

  clearSearchField() {
    this.formControl.setValue('');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
