import { Directive, Input } from '@angular/core';
import { FormValidators } from 'app/armp/shared/form-validators';
import { CustomValidatorDirective } from 'app/armp/models/custom-validator';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
@Directive({
    selector: `[validateMax][formControlName],[validateMax][formControl],[validateMax][ngModel]`,
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: MaxDirective,
            multi: true,
        },
    ],
    standalone: false
})
export class MaxDirective extends CustomValidatorDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('validateMax') enabled: any;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('validateMaxValue') max: number;

  protected _validate(formControl: AbstractControl): ValidationErrors {
    return FormValidators.maxValidator(formControl.value, this.max);
  }
}
