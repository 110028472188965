import { Component, OnInit, Input, forwardRef, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
import { CustomControl } from 'app/armp/models/custom-control';

@Component({
    selector: 'app-policy-cfg',
    templateUrl: './policy-cfg.component.html',
    styleUrls: ['./policy-cfg.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PolicyCfgComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PolicyCfgComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class PolicyCfgComponent extends CustomControl<any> implements OnInit, AfterViewInit {
  errorKey = 'policyCfg';

  @Input() viewMode: 'normal' | 'inline' = 'normal';
  @Input() editMode = true;
  @Input() showActual = false;
  @Input() required: boolean;

  private _periodicity: any;

  periodicities = [
    {
      code: 'monthly',
      label: 'Mensile',
      days: 30,
    },
    {
      code: 'bimestrial',
      label: 'Bimestrale',
      days: 60,
    },
    {
      code: 'quarterly',
      label: 'Trimestrale',
      days: 120,
    },
    {
      code: 'yearly',
      label: 'Annuale',
      days: 365,
    },
    {
      code: 'custom',
      label: 'Personalizzata',
      days: null,
    },
  ];

  ngOnInit() {}

  ngAfterViewInit() {}

  public get periodicity(): any {
    if (this.value) {
      const p = this.periodicities.find((p) => this.value.autoRenewExtension === p.days);

      if (p) {
        return p;
      }
      if (!p && this.value.autoRenewExtension) {
        return this.periodicities[this.periodicities.length - 1];
      }
    } else {
      return null;
    }
  }

  public set periodicity(v: any) {
    this.value.autoRenewExtension = null;
    if (v && v.days) {
      this.value.autoRenewExtension = v.days;
    }
  }

  public onAutoRenewChange() {
    if (!this.value.autoRenew) {
      this.value.autoRenewExtension = 0;
    }
  }

  min(...values: number[]): number {
    return Math.min(...this.sanitizeArray(values));
  }

  max(...values: number[]): number {
    return Math.max(...this.sanitizeArray(values));
  }

  private sanitizeArray(values?: number[]): number[] {
    if (values) {
      return values.map((x) => (x ? x : 0));
    }
    return [];
  }
}
