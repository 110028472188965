import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {TranslateService} from '@ngx-translate/core';

import {ApiPublicCruService} from 'app/armp/crud/services';
import {ToastService} from './toast.service';
import {ConfigService} from './config.service';

@Injectable()
export class VoucherPublicService extends ApiPublicCruService<any> {
    constructor(
        configService: ConfigService,
        toastService: ToastService,
        http: HttpClient,
        translator: TranslateService
    ) {
        super(configService, toastService, http, translator);
    }

    resourceName(): string {
        return 'voucher';
    }

    getByCode(code: string, headers: HttpHeaders) {
        return this.httpHandler.httpGet('/code/' + code, {headers});
    }

    getOrderExternalLink(voucherId: number, code: string) {
        return this.httpHandler.httpGet(
            `/${voucherId}/order-external-link?code=${code}`
        );
    }

    getStatus(voucherId: number, code: string, headers: HttpHeaders) {
        return this.httpHandler.httpGet('/' + voucherId + '/status?code=' + code, {
            headers,
        });
    }

    uploadMachineId(voucher: any, file: File, headers: HttpHeaders) {
        const data = new FormData();
        data.append('certificateFile', file);

        return this.httpHandler.httpPost(
            `/${voucher.id}/machine-id?code=${voucher.code}`,
            data,
            {headers, responseType: 'json'}
        );
    }
}
