import { Component, OnInit, Input, ContentChild, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-section-header',
    templateUrl: './section-header.component.html',
    styleUrls: ['./section-header.component.scss'],
    standalone: false
})
export class SectionHeaderComponent implements OnInit {
  @Input()
  breadcrumb = [];
  @Input()
  title: string;

  @Input()
  showBack = true;

  @ContentChild('headerLinksTemplate')
  headerLinksTemplate: TemplateRef<any>;

  constructor(private location: Location) {}

  ngOnInit() {}

  goBack() {
    this.location.back();
  }
}
