<form #form="ngForm" novalidate>
  <mat-card
    appearance="outlined"
    class="mb-4 mat-sub-card"
    *ngFor="let certificate of certificates; let certificateIndex = index"
    ngModelGroup="{{ certificate.__cid }}"
  >
    <mat-card-title>
      Certificato {{ certificate.id ? certificate.name : "Aggiuntivo " }}
    </mat-card-title>

    <mat-card-content>
      <mat-list-item *ngIf="_isReadonly">
        <h3 matLine>Tipo</h3>
        <p matLine>{{ certificate.type }}</p>
      </mat-list-item>

      <mat-form-field class="w-100" *ngIf="!_isReadonly">
        <mat-label>Tipo</mat-label>
        <mat-select
          [(ngModel)]="certificate.type"
          (selectionChange)="loadCertificates(certificate.type)"
          required
          name="type"
        >
          <mat-option [value]="undefined">Seleziona</mat-option>
          <mat-option
            *ngFor="let certificateType of certificateTypes"
            [value]="certificateType.id"
            [disabled]="isAlreadySelected(certificateType)"
          >
            {{ certificateType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-form-control-error *ngIf="!_isReadonly" [form]="form" control="type">
      </app-form-control-error>

      <div *ngIf="certificate.type">
        <mat-list-item *ngIf="_isReadonly">
          <h3 matLine>Certificato</h3>
          <p matLine>{{ certificate.name }}</p>
        </mat-list-item>

        <mat-form-field class="w-100" *ngIf="!_isReadonly">
          <mat-label>Certificato</mat-label>
          <mat-select
            [(ngModel)]="certificate.id"
            name="certificateId"
            required
          >
            <mat-option [value]="undefined">Seleziona</mat-option>
            <mat-option
              *ngFor="let _certificate of crts[certificate.type]"
              [value]="_certificate.id"
            >
              {{ _certificate.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-form-control-error
          *ngIf="!_isReadonly"
          [form]="form"
          control="certificateId"
        >
        </app-form-control-error>
      </div>
    </mat-card-content>

    <mat-card-actions align="end">
      <button mat-icon-button (click)="removeCertificate(certificateIndex)">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>

  <div
    class="d-flex align-items-center pt-3 justify-content-end"
    *ngIf="canManageCertificates && !_isReadonly"
  >
    <button
      mat-flat-button
      type="button"
      color="accent"
      (click)="addCertificate()"
      [disabled]="!canAddCertificate"
    >
      Associa Certificato
    </button>

    <ng-content select="[certificateManagementActions]"></ng-content>
  </div>
</form>
