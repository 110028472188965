import { Component, OnInit, Input, Output, EventEmitter, ViewChild, forwardRef } from '@angular/core';
import { PolicyService } from 'app/armp/services/policy.service';
import { NgForm, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { CustomControl } from 'app/armp/models/custom-control';
import { UUID } from 'angular2-uuid';

@Component({
    selector: 'app-policy-management',
    templateUrl: './policy-management.component.html',
    styleUrls: ['./policy-management.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PolicyManagementComponent),
            multi: true,
        },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PolicyManagementComponent), multi: true },
    ],
    standalone: false
})
export class PolicyManagementComponent extends CustomControl<Array<any>> implements OnInit {
  errorKey = 'policies';

  @Input()
  scope: string;

  @Output()
  removed = new EventEmitter<any>();

  @ViewChild('form', { static: true })
  form: NgForm;

  private policyImplementations = [];

  constructor(private policyService: PolicyService) {
    super();
  }

  ngOnInit() {
    this.policyService.getImplementations(this.scope).then((p) => (this.policyImplementations = p));
  }

  addPolicy() {
    this.value.push({});
    this.propagateOnChange(this.value);
    this.propagateOnTouched();
  }

  removePolicy(index: number) {
    const policy = this.value[index];

    this.value.splice(index, 1);

    this.removed.emit(policy);

    this.propagateOnChange(this.value);

    this.propagateOnTouched();
  }

  getAvailablePolicyImplementations(policyIndex?: number): Array<any> {
    return this.policyService.getAvailablePolicyImplementations(
      this.policyImplementations,
      this.value,
      policyIndex
    );
  }

  get policies() {
    if (!this.value) {
      return [];
    }

    return this.value.map((x) => {
      if (!x.__cid) {
        x.__cid = UUID.UUID();
      }

      return x;
    });
  }

  public get canAddPolicy() {
    return !this.disabled && this.getAvailablePolicyImplementations().length > 0;
  }

  public onImplementationChange(policy) {
    policy.code = null;
    if (policy.implementation) {
      policy.code = policy.implementation.code;
    }
  }
}
