import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../services/config.service';

import {ToastService} from '../../services/toast.service';
import {BaseResponse} from '../../models/response/baseResponse';
import {firstValueFrom, Observable} from 'rxjs';
import {HttpOptionsEncoded, HttpOptionsJson} from '../../models/request/httpOptions';

export class ApiHttpHandler {

    get url(): string {
        return `${this.apiEndpoint}/${this.endpoint}`;
    }

    constructor(
        private endpoint: string,
        private toastService: ToastService,
        private http: HttpClient,
        private translator: TranslateService,
        private apiEndpoint: string
    ) {
    }

    public httpDownload(
        url: string,
        options?: {
            headers?: HttpHeaders;
            observe?: 'body';
            params?:
                | HttpParams
                | {
                [param: string]: string | string[];
            };
            reportProgress?: boolean;
            responseType: 'blob';
            withCredentials?: boolean;
        }
    ): Promise<any> {
        options = options || {
            headers: new HttpHeaders(),
            observe: 'body',
            params: new HttpParams(),
            reportProgress: false,
            responseType: 'blob',
            withCredentials: false,
        };
        options.responseType = 'blob';

        return new Promise<any>((resolve, reject) => {
            // @ts-ignore
            this.http.get<BaseResponse>(this.url + url, options)
                .toPromise()
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    }

    /** get con ritorno: Promise */
    public httpGet(url: string, options?: HttpOptionsJson): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            firstValueFrom(this.httpGetBase(url, options))
                .then((response) => {
                    if (!this.checkAndManageErrors(response, reject)) {
                        resolve(response.payload);
                    }
                })
                .catch((err) => reject(err));
        });
    }

    /** get con ritorno: Observable */
    httpGetBase(url: string, options?: HttpOptionsJson): Observable<any> {
        return this.http.get<BaseResponse>(this.url + url, options);
    }

    /** post con ritorno: Promise */
    public httpPost(url: string, body?: any, options?: HttpOptionsJson): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            firstValueFrom(this.httpPostBase(url, body, options))
                .then((response) => {
                    if (!this.checkAndManageErrors(response, reject)) {
                        resolve(response.payload);
                    }
                })
                .catch((err) => reject(err));
        });
    }

    /** post con ritorno: Observable */
    httpPostBase(url: string, body?: any, options?: HttpOptionsJson): Observable<any> {
        return this.http.post<BaseResponse>(this.url + url, body, options);
    }

    public httpPostUrlEncoded(url: string, body?: HttpParams, options?: HttpOptionsEncoded): Promise<any> {

        if (!options) {
            options = {
                headers: new HttpHeaders(),
                observe: 'body',
                params: new HttpParams(),
                reportProgress: false,
                responseType: 'json',
                withCredentials: false,
            };
        }

        if (!options.headers) {
            options.headers = new HttpHeaders();
        }

        options.headers.set('Content-type', 'application/x-www-form-urlencoded');

        return new Promise<any>((resolve, reject) => {
            this.http
                .post<BaseResponse>(this.url + url, body, options)
                .toPromise()
                .then((res) => {
                    if (!this.checkAndManageErrors(res, reject)) {
                        resolve(res.payload);
                    }
                })
                .catch((err) => reject(err));
        });
    }

    public httpPut(
        url: string,
        body?: any,
        options?: {
            headers?:
                | HttpHeaders
                | {
                [header: string]: string | string[];
            };
            observe?: 'body';
            params?:
                | HttpParams
                | {
                [param: string]: string | string[];
            };
            reportProgress?: boolean;
            responseType: 'json';
            withCredentials?: boolean;
        }
    ): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.http
                .put<BaseResponse>(this.url + url, body, options)
                .toPromise()
                .then((res) => {
                    if (!this.checkAndManageErrors(res, reject)) {
                        resolve(res.payload);
                    }
                })
                .catch((err) => reject(err));
        });
    }

    public httpDelete(
        url: string,
        options?: {
            headers?:
                | HttpHeaders
                | {
                [header: string]: string | string[];
            };
            observe?: 'body';
            params?:
                | HttpParams
                | {
                [param: string]: string | string[];
            };
            reportProgress?: boolean;
            responseType: 'json';
            withCredentials?: boolean;
        }
    ): Promise<any> {
        return new Promise<BaseResponse>((resolve, reject) => {
            this.http
                .delete<any>(this.url + url, options)
                .toPromise()
                .then((res) => {
                    if (!this.checkAndManageErrors(res, reject)) {
                        resolve(res.payload);
                    }
                })
                .catch((err) => reject(err));
        });
    }

    /** @deprecated - this feature has been moved into CustomHttpInterceptor and may be canceled in the future */
    private checkAndManageErrors(response: any, reject: (reason: any) => void) {
        if (response.metadata.errors !== null && response.metadata.errors.length > 0) {
            this.toastService.error({
                message: this.translator.instant('TOASTR.ERROR') + ': ' + response.metadata.errors[0].message,
            });

            reject(response.metadata);
            return true;
        }

        return false;
    }
}
