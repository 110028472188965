import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CaptchaService} from 'app/armp/core/captcha.service';

import {LoaderService} from 'app/armp/core/loader.service';
import {LicenseInstancePublicService} from 'app/armp/services/license-instance-public.service';
import {LicenseInstanceService} from 'app/armp/services/license-instance-service';
import {VoucherPublicService} from 'app/armp/services/voucher-public.service';
import {VoucherService} from 'app/armp/services/voucher.service';
import {
  VoucherStatusComponent
} from 'app/armp/voucher/voucher-edit/voucher-status/voucher-status/voucher-status.component';
import {ConfigService} from '../../services/config.service';

@Component({
    selector: 'app-voucher-public-edit',
    templateUrl: './voucher-public-edit.component.html',
    styleUrls: ['./voucher-public-edit.component.scss'],
    providers: [
        {
            provide: VoucherService,
            useClass: VoucherPublicService,
        },
        {
            provide: LicenseInstanceService,
            useClass: LicenseInstancePublicService,
        },
    ],
    standalone: false
})
export class VoucherPublicEditComponent implements OnInit {
    @ViewChild(VoucherStatusComponent, {static: true}) voucherStatus!: VoucherStatusComponent;

    siteKey: string;
    voucher: any;

    constructor(
        private voucherService: VoucherPublicService,
        private loaderService: LoaderService,
        private activatedRoute: ActivatedRoute,
        private captchaService: CaptchaService,
        private configService: ConfigService
    ) {
        this.siteKey = this.configService.config.captcha.siteKey;
        this.voucher = this.activatedRoute.snapshot.data['voucher'];
    }

    ngOnInit() {
    }

    async refreshVoucher() {
        const headers = await this.captchaService.getCaptchaHeader();
        this.loaderService.show();
        this.voucherService
            .getByCode(this.voucher.code, headers)
            .then((voucher) => {
                this.loaderService.hide();
                this.voucher = voucher;
                this.voucherStatus.refresh();
            })
            .catch(() => this.loaderService.hide());
    }
}
