<div class="container d-flex justify-content-center pt-5">
  <div class="col-24 col-sm-12 col-md-10">
    <form #form="ngForm" ngForm novalidate (ngSubmit)="goToVoucher()">
      <mat-card appearance="outlined">
        <mat-card-title>Accedi</mat-card-title>

        <mat-card-content>
          <mat-form-field class="w-100 mt-4">
            <mat-label>Codice voucher</mat-label>
            <input
              matInput
              placeholder="Codice voucher"
              type="text"
              name="voucherCode"
              required
              [(ngModel)]="voucherCode"
              #voucherCodeModel="ngModel"
            />
            <mat-error
              appFormFieldError
              [formFieldModel]="voucherCodeModel"
              [form]="form"
            ></mat-error>
          </mat-form-field>
        </mat-card-content>

        <mat-card-actions align="end">
          <button
            mat-flat-button
            color="primary"
            type="submit"
            [disabled]="!form.valid"
          >
            Cerca
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</div>
