<div>
  <app-page-header
    title="Crea nuovo voucher"
    [showBack]="authService.user.profile.name !== 'CUSTOMER'"
    [breadcrumb]="['Voucher']"
  >
    <div class="d-flex">
      <button
        mat-stroked-button
        color="primary"
        class="mr-3"
        (click)="onBack()"
      >
        Annulla
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="save()"
        *ngIf="customer.id && authService.hasRole('VOUCHER_CAN_WRITE')"
      >
        Salva
      </button>
    </div>
  </app-page-header>

  <div class="container-fluid py-3">
    <mat-card appearance="outlined" class="w-75 m-auto" *ngIf="!customer.id">
      <mat-card-title class="d-flex align-items-center">
        <mat-icon class="mr-2" color="primary">portrait</mat-icon> Dati cliente
      </mat-card-title>
      <mat-card-content>
        <form (ngSubmit)="searchCustomer()" class="mt-4">
          <div class="d-flex align-items-center">
            <mat-form-field class="w-100 form-field-without-padding">
              <mat-label>Recupera dati cliente</mat-label>
              <input
                matInput
                name="customerSearchText"
                placeholder="Inserisci Ragione sociale o Partita IVA"
                [(ngModel)]="customerSearchText"
              />
            </mat-form-field>

            <button mat-icon-button type="submit" class="ml-2">
              <mat-icon>search</mat-icon>
            </button>
          </div>
        </form>

        <div class="mt-4" *ngIf="customerSearchResult?.length">
          <div
            *ngFor="let result of customerSearchResult; let index = index"
            class="py-3"
            [ngClass]="{
              'border-bottom': index < customerSearchResult.length - 1
            }"
          >
            <div class="text-uppercase">
              <span>{{ result.displayName }}</span>
              <span class="ml-2" *ngIf="result.organization">
                P.Iva {{ result.vatNumber }}</span
              >
              <span class="ml-2" *ngIf="!result.organization">
                C.Fisc. {{ result.fiscalCode }}</span
              >
            </div>
            <div class="mt-2">
              <button
                mat-stroked-button
                color="primary"
                class="mr-2"
                (click)="goToCustomerDetail(result)"
              >
                Vedi dettaglio
              </button>
              <button
                mat-flat-button
                color="primary"
                (click)="selectCustomer(result)"
              >
                Associa cliente
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions></mat-card-actions>
    </mat-card>

    <form #form="ngForm" novalidate class="" *ngIf="customer.id">
      <div class="w-100">
        <mat-card appearance="outlined" class="mat-elevation-z0 border">
          <mat-card-title
            class="d-flex align-items-center justify-content-between"
          >
            <div class="d-flex align-items-center">
              <mat-icon class="mr-2" color="primary">portrait</mat-icon> Dati
              cliente
            </div>
            <div>
              <button
                mat-flat-button
                type="button"
                (click)="backToCustomerSearch()"
                color="primary"
              >
                Torna alla ricerca cliente
              </button>
            </div>
          </mat-card-title>
          <mat-card-content>
            <div class="d-flex">
              <div class="px-3">
                <h4 matLine class="font-weight-bold">Ragione Sociale</h4>
                <p matLine>
                  <span> {{ customer.name }} </span>
                </p>
              </div>

              <div class="px-3" *ngIf="customer.organization">
                <h4 matLine class="font-weight-bold">Partita IVA</h4>
                <p matLine>
                  <span> {{ customer.vatNumber }} </span>
                </p>
              </div>

              <div class="px-3" *ngIf="!customer.organization">
                <h4 matLine class="font-weight-bold">Codice Fiscale</h4>
                <p matLine>
                  <span> {{ customer.fiscalCode }} </span>
                </p>
              </div>

              <div class="px-3">
                <h4 matLine class="font-weight-bold">Indirizzo</h4>
                <p matLine>
                  <span [hidden]="!customer.address"
                    >{{ customer.address }} - </span
                  >{{ customer.city }}
                  <span [hidden]="!customer.state">({{ customer.state }})</span>
                </p>
              </div>

              <div class="px-3">
                <h4 matLine class="font-weight-bold">Nome contatto</h4>
                <p matLine>{{ customer.firstName }} {{ customer.lastName }}</p>
              </div>

              <div class="px-3">
                <h4 matLine class="font-weight-bold">Contatto</h4>
                <p matLine>
                  {{ customer.email }}
                </p>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions></mat-card-actions>
        </mat-card>
      </div>

      <div class="w-75 m-auto pt-4">
        <mat-card appearance="outlined">
          <mat-card-title class="d-flex align-items-center">
            <mat-icon class="mr-2" color="primary">receipt_long</mat-icon> Dati
            ordine
          </mat-card-title>
          <mat-card-content>
            <div class="d-flex align-items-center">
              <mat-form-field class="w-100" *ngIf="!internalContact">
                <mat-label>Referente commerciale</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="internalContactFormControl"
                  [(ngModel)]="voucher.internalContactName"
                />
              </mat-form-field>
            </div>

            <mat-list *ngIf="internalContact">
              <div mat-subheader class="pb-0">Referente commerciale</div>
              <mat-list-item>
                <mat-icon
                  matListIcon
                  class="cursor-pointer"
                  (click)="
                    internalContact = null;
                    internalContactFormControl.setValue(null)
                  "
                  >delete
                </mat-icon>
                <h3 matLine class="d-flex align-items-center">
                  <span>{{ internalContact.name }}</span>
                </h3>
                <p matLine>
                  <span> {{ internalContact.email }} </span>
                  <span> {{ internalContact.phone }} </span>
                </p>
              </mat-list-item>
            </mat-list>

            <div *ngIf="newInternalContactVisible && !internalContact">
              <mat-form-field class="w-100">
                <mat-label>Email Ref. Commerciale:</mat-label>
                <input
                  matInput
                  type="email"
                  validateEmail
                  placeholder="Email Ref. Commerciale:"
                  name="internalContactEmail"
                  maxlength="255"
                  [(ngModel)]="voucher.internalContactEmail"
                />
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Telefono Ref. Commerciale:</mat-label>
                <input
                  matInput
                  placeholder="Telefono Ref. Commerciale:"
                  name="internalContactPhone"
                  maxlength="255"
                  [(ngModel)]="voucher.internalContactPhone"
                />
              </mat-form-field>
            </div>

            <mat-form-field class="w-100">
              <mat-label>Numero Ordine</mat-label>
              <input
                matInput
                placeholder="Numero Ordine"
                name="orderNo"
                maxlength="255"
                [(ngModel)]="voucher.orderNo"
                #orderNo="ngModel"
                required
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="orderNo"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Data ordine</mat-label>
              <input
                matInput
                [matDatepicker]="orderDatePicker"
                name="orderDate"
                [(ngModel)]="voucher.orderDate"
                #orderDate="ngModel"
                [max]="today"
                [readonly]="true"
                (click)="orderDatePicker.open()"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="orderDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #orderDatePicker></mat-datepicker>
              <mat-error
                appFormFieldError
                [formFieldModel]="orderDate"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Sistema Esterno Ordine</mat-label>
              <mat-select
                name="orderSystemId"
                [(ngModel)]="voucher.orderSystemId"
              >
                <mat-option [value]="undefined"
                  >Seleziona il sistema</mat-option
                >
                <mat-option *ngFor="let system of systems" [value]="system.id">
                  {{ system.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Nome Contatto</mat-label>
              <input
                matInput
                placeholder="Nome Contatto"
                name="contactName"
                maxlength="255"
                [(ngModel)]="voucher.contactName"
                #contactName="ngModel"
                required
              />
              <mat-hint align="end"
                >{{ voucher?.contactName?.length || 0 }}/255</mat-hint
              >
              <mat-error
                appFormFieldError
                [formFieldModel]="contactName"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Email Contatto</mat-label>
              <input
                matInput
                type="email"
                placeholder="Email Contatto"
                name="contactEmail"
                maxlength="255"
                [(ngModel)]="voucher.contactEmail"
                #contactEmail="ngModel"
                validateEmail
                required
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="contactEmail"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Telefono Contatto</mat-label>
              <input
                matInput
                placeholder="Telefono Contatto"
                name="contactPhone"
                maxlength="255"
                [(ngModel)]="voucher.contactPhone"
                #contactPhone="ngModel"
                required
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="contactPhone"
                [form]="form"
              ></mat-error>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="w-75 m-auto pt-4">
        <mat-card appearance="outlined">
          <mat-card-title class="d-flex align-items-center">
            <mat-icon class="mr-2" color="primary">description</mat-icon> Dati
            voucher
          </mat-card-title>
          <mat-card-content>
            <mat-checkbox
              class="pt-3 pb-2"
              name="autoRenew"
              [(ngModel)]="voucher.autoRenew"
              >Auto rinnovo</mat-checkbox
            >

            <mat-form-field class="w-100">
              <mat-label>Modalità di Scadenza Licenze</mat-label>
              <mat-select
                name="licenseExpirationMode"
                [(ngModel)]="voucher.licenseExpirationMode"
                #licenseExpirationMode="ngModel"
                (selectionChange)="onLicenseExpirationModeChange()"
                required
              >
                <mat-option [value]="undefined">Seleziona</mat-option>
                <mat-option
                  *ngFor="let licenseExpirationMode of licenseExpirationModes"
                  [value]="licenseExpirationMode.id"
                >
                  {{ licenseExpirationMode.name }}
                </mat-option>
              </mat-select>
              <mat-error
                appFormFieldError
                [formFieldModel]="licenseExpirationMode"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Scadenza</mat-label>
              <input
                matInput
                [matDatepicker]="expiresOnPicker"
                name="expiresOn"
                [(ngModel)]="voucher.expiresOn"
                #expiresOn="ngModel"
                [min]="tomorrow"
                required
                [readonly]="true"
                (click)="expiresOnPicker.open()"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="expiresOnPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #expiresOnPicker></mat-datepicker>
              <mat-error
                appFormFieldError
                [formFieldModel]="expiresOn"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100" *ngIf="validity">
              <mat-label>Periodo Validità</mat-label>
              <input
                matInput
                type="number"
                placeholder="Giorni"
                name="licenseInstanceValidity"
                maxlength="255"
                [(ngModel)]="voucher.licenseInstanceValidity"
                #licenseInstanceValidity="ngModel"
                disabled="{{ !validity }}"
                required="{{ validity }}"
                [min]="1"
                [max]="99999"
              />
              <span matSuffix>Giorni</span>
              <mat-error
                appFormFieldError
                [formFieldModel]="licenseInstanceValidity"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>Max Riassegnazioni Postazioni</mat-label>
              <input
                matInput
                type="number"
                placeholder="Max Riassegnazioni Postazioni"
                name="maxReassignments"
                maxlength="255"
                [(ngModel)]="voucher.maxReassignments"
                #maxReassignments="ngModel"
                disabled="{{ !validity }}"
                required="{{ validity }}"
                [min]="1"
                [max]="99999"
              />
              <mat-error
                appFormFieldError
                [formFieldModel]="maxReassignments"
                [form]="form"
              ></mat-error>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="w-75 m-auto pt-4">
        <mat-card appearance="outlined">
          <mat-card-title class="d-flex align-items-center">
            <mat-icon class="mr-2" color="primary">inventory_2</mat-icon> Dati
            prodotto
          </mat-card-title>
          <mat-card-content>
            <mat-form-field class="w-100">
              <mat-label>Scegli Prodotto</mat-label>
              <mat-select
                name="product"
                [(ngModel)]="product"
                #productModel="ngModel"
                (selectionChange)="onProductChange()"
                required
              >
                <mat-option [value]="undefined">Seleziona</mat-option>
                <mat-option *ngFor="let product of products" [value]="product">
                  {{ product.name }}
                </mat-option>
              </mat-select>
              <mat-error
                appFormFieldError
                [formFieldModel]="productModel"
                [form]="form"
              ></mat-error>
            </mat-form-field>

            <ng-container *ngIf="product?.id" class="mt-4">
              <mat-form-field class="w-100">
                <mat-label>Template Licenza</mat-label>
                <mat-select
                  name="licenseTemplate"
                  [(ngModel)]="licenseTemplate"
                >
                  <mat-option [value]="undefined">Seleziona</mat-option>
                  <mat-option
                    *ngFor="let licenseTemplate of licenseTemplates"
                    [value]="licenseTemplate"
                  >
                    {{ licenseTemplate.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div [hidden]="!licenseTemplate?.id" class="mb-2">
                Policy associate:
                <div class="mt-2">
                  <div *ngFor="let policy of licenseTemplate?.policies">
                    <mat-checkbox
                      class="py-2"
                      [name]="policy.id"
                      [(ngModel)]="selectedPolicies[policy.id]"
                      (change)="
                        onLicenseInstancePolicySelected(
                          policy,
                          selectedPolicies[policy.id]
                        )
                      "
                    >
                      {{ policy.name }}
                      ({{ policy.implementation.name }})</mat-checkbox
                    >
                  </div>
                </div>
              </div>

              <app-policy-management
                [(ngModel)]="policies"
                (removed)="policyRemoved($event)"
                name="policies"
                scope="VOUCHER"
                [topForm]="form"
              >
              </app-policy-management>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="w-75 m-auto pt-4">
        <mat-card appearance="outlined">
          <mat-card-title> Certificati Associati </mat-card-title>
          <mat-card-content>
            <div class="mt-4">
              <app-certificate-management
                [(ngModel)]="certificates"
                name="certificates"
                [canManageCertificates]="
                  authService.hasRole('VOUCHER_CAN_WRITE')
                "
              >
              </app-certificate-management>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="w-75 m-auto pt-4">
        <mat-card appearance="outlined">
          <mat-card-title class="d-flex align-items-center">
            Firma Di Sigillo Associata
          </mat-card-title>
          <mat-card-content>
            <div class="mt-4">
              <mat-card appearance="outlined" *ngIf="canAddAutomaticUser">
                <mat-form-field class="w-100">
                  <mat-label>Titolare</mat-label>
                  <input
                    matInput
                    placeholder="Titolare"
                    name="user"
                    maxlength="255"
                    [(ngModel)]="automaticUser.user"
                    #user="ngModel"
                    required
                  />
                  <mat-hint align="end"
                    >{{ automaticUser?.user?.length || 0 }}/255</mat-hint
                  >
                  <mat-error
                    appFormFieldError
                    [formFieldModel]="user"
                    [form]="form"
                  ></mat-error>
                </mat-form-field>

                <mat-form-field class="w-100">
                  <mat-label>Dominio Titolare</mat-label>
                  <input
                    matInput
                    placeholder="Dominio Titolare"
                    name="domain"
                    maxlength="255"
                    [(ngModel)]="automaticUser.domain"
                    #domain="ngModel"
                    required
                  />
                  <mat-hint align="end"
                    >{{ automaticUser?.domain?.length || 0 }}/255</mat-hint
                  >
                  <mat-error
                    appFormFieldError
                    [formFieldModel]="domain"
                    [form]="form"
                  ></mat-error>
                </mat-form-field>

                <mat-form-field class="w-100">
                  <mat-label>Delegato</mat-label>
                  <input
                    matInput
                    placeholder="Delegato"
                    name="delegatedUser"
                    maxlength="255"
                    [(ngModel)]="automaticUser.delegatedUser"
                    #delegatedUser="ngModel"
                    required
                  />
                  <mat-hint align="end"
                    >{{
                      automaticUser?.delegatedUser?.length || 0
                    }}/255</mat-hint
                  >
                  <mat-error
                    appFormFieldError
                    [formFieldModel]="delegatedUser"
                    [form]="form"
                  ></mat-error>
                </mat-form-field>

                <mat-form-field class="w-100">
                  <mat-label>Dominio Delegato</mat-label>
                  <input
                    matInput
                    placeholder="Dominio Delegato"
                    name="delegatedDomain"
                    maxlength="255"
                    [(ngModel)]="automaticUser.delegatedDomain"
                    #delegatedDomain="ngModel"
                    required
                  />
                  <mat-hint align="end"
                    >{{
                      automaticUser?.delegatedDomain?.length || 0
                    }}/255</mat-hint
                  >
                  <mat-error
                    appFormFieldError
                    [formFieldModel]="delegatedDomain"
                    [form]="form"
                  ></mat-error>
                </mat-form-field>

                <mat-form-field class="w-100">
                  <mat-label>Password</mat-label>
                  <input
                    matInput
                    type="password"
                    placeholder="Password Delegato"
                    name="delegatedPassword"
                    maxlength="255"
                    [(ngModel)]="automaticUser.delegatedPassword"
                    #delegatedPassword="ngModel"
                    required
                  />
                  <mat-hint align="end"
                    >{{
                      automaticUser?.delegatedPassword?.length || 0
                    }}/255</mat-hint
                  >
                  <mat-error
                    appFormFieldError
                    [formFieldModel]="delegatedPassword"
                    [form]="form"
                  ></mat-error>
                </mat-form-field>

                <mat-form-field class="w-100">
                  <mat-label>Pin</mat-label>
                  <input
                    matInput
                    type="password"
                    placeholder="Pin"
                    name="pin"
                    maxlength="255"
                    [(ngModel)]="automaticUser.pin"
                    #pin="ngModel"
                    required
                  />
                  <mat-hint align="end"
                    >{{ automaticUser?.pin?.length || 0 }}/255</mat-hint
                  >
                  <mat-error
                    appFormFieldError
                    [formFieldModel]="pin"
                    [form]="form"
                  ></mat-error>
                </mat-form-field>

                <mat-card-actions align="end">
                  <button mat-icon-button (click)="removeAutomaticUser()">
                    <mat-icon>delete</mat-icon>
                  </button>
                </mat-card-actions>
              </mat-card>

              <div class="d-flex align-items-center pt-3 justify-content-end">
                <button
                  mat-flat-button
                  type="button"
                  color="accent"
                  (click)="addAutomaticUser()"
                  [disabled]="canAddAutomaticUser"
                >
                  Associa Firma Di Sigillo
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <mat-toolbar
        class="d-flex mt-4 justify-content-end border-top bg-transparent"
      >
        <button
          mat-stroked-button
          type="button"
          color="primary"
          class="mr-3"
          (click)="onBack()"
        >
          Annulla
        </button>
        <button
          mat-flat-button
          type="button"
          color="primary"
          (click)="save()"
          *ngIf="customer.id && authService.hasRole('VOUCHER_CAN_WRITE')"
        >
          Salva
        </button>
      </mat-toolbar>
    </form>
  </div>
</div>
