<mat-card appearance="outlined" *ngIf="versionResponse">
    <mat-card-title class="text-primary d-flex align-items-center">
        <mat-icon class="mr-2">extension</mat-icon>
        Versione associata
    </mat-card-title>
    <mat-card-content>

        <form *ngIf="formGroup"
              [formGroup]="formGroup"
              class="w-100 d-flex justify-content-between">

            <div class="w-25">
                <!-- LAST VERSION -->
                <mat-checkbox [formControl]="formGroup.controls.lastVersion"
                              class="mt-4">
                    Usare l'ultima versione
                </mat-checkbox>
            </div>


            <div *ngIf="!formGroup.controls.lastVersion.value"
                 [formArrayName]="'version'"
                 class="w-75 pt-5 pl-3 d-flex flex-column">

                <!-- VERSION SELECT LIST -->
                <div *ngFor="let versionFormGroup of formArrayVersion.controls; let i = index"
                     class="d-flex align-items-center justify-content-between shadow-sm">

                    <!-- CLASSIFIER -->
                    <div class="p-2 text-capitalize font-weight-bold">
                        {{versionFormGroup.controls.classifier.value}}
                    </div>
                    <!-- / CLASSIFIER -->


                    <!-- VERSION -->
                    <mat-form-field [formGroup]="versionFormGroup"
                                    [hideRequiredMarker]="versionFormGroup.valid"
                                    class="w-25">
                        <mat-label>Versione</mat-label>
                        <mat-select class="form-control off-bxshadow"
                                    [compareWith]="compareOgg"
                                    [formControl]="versionFormGroup.controls.version">
                            <mat-option [value]="undefined">Seleziona la versione</mat-option>
                            <mat-option
                                    *ngFor="let version of allVersionList.has(versionFormGroup.controls.classifier.value) ? allVersionList.get(versionFormGroup.controls.classifier.value) : []"
                                    [value]="version">{{version.viewValue}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="versionFormGroup.controls.version.hasError('required')">
                            {{'VALIDATION.REQUIRED' | translate}}
                        </mat-error>

                    </mat-form-field>

                    <!-- VERSION -->

                </div>
                <!-- / VERSION SELECT LIST -->

            </div>
        </form>

        <div *ngIf="formGroup" class="w-100 mt-3 d-flex justify-content-end">
            <button mat-stroked-button
                    color="primary"
                    class="mr-5"
                    (click)="saveVersion()">
                <div class="d-flex align-items-center">
                    <mat-icon class="mr-2">save</mat-icon>
                    Save
                </div>
            </button>
        </div>

    </mat-card-content>
</mat-card>
