<div>
  <!-- HEADER -->
  <app-page-header
    [title]="'ID ' + voucher.code + ' | ' + voucher.customer.name"
    [showBack]="!isReadonly"
    [breadcrumb]="['Voucher']"
  >
    <div class="d-flex">
      <button
        mat-stroked-button
        color="primary"
        class="mr-3"
        (click)="refreshVoucher()"
      >
        <mat-icon class="mr-2">sync</mat-icon>
        Aggiorna
      </button>
      <button
        mat-flat-button
        color="primary"
        class="mr-3"
        (click)="deactivate()"
        *ngIf="
          voucher.active &&
          !isReadonly &&
          authService.hasRole('VOUCHER_CAN_WRITE')
        "
      >
        Disattiva Voucher
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="activate()"
        *ngIf="
          !voucher.active &&
          !isReadonly &&
          authService.hasRole('VOUCHER_CAN_WRITE')
        "
      >
        Attiva Voucher
      </button>
    </div>
  </app-page-header>
  <!-- / HEADER -->

  <div class="container-fluid my-3">
    <!-- Voucher status -->
    <div>
      <app-voucher-status [voucher]="voucher" [isReadonly]="isReadonly">
      </app-voucher-status>
    </div>

    <!-- Dettagli -->
    <div class="row mt-4">
      <div class="col-6">
        <mat-card appearance="outlined">
          <mat-card-title class="text-primary">
            Certificati associati
          </mat-card-title>

          <mat-card-content>
            <div ngForm #certificatesForm="ngForm" class="pt-2">
              <app-alert
                text="Nessun certificato specifico presente"
                type="info"
                *ngIf="!certificates.length"
              >
              </app-alert>

              <app-certificate-management
                [(ngModel)]="certificates"
                [isReadonly]="isReadonly"
                name="certificates"
                [canManageCertificates]="
                  authService.hasRole('VOUCHER_CAN_WRITE')
                "
              >
                <ng-container certificateManagementActions>
                  <button
                    mat-flat-button
                    color="primary"
                    class="ml-2"
                    *ngIf="!isReadonly"
                    type="button"
                    (click)="saveCertificates()"
                    [disabled]="
                      certificatesForm.controls.certificates?.pristine ||
                      certificatesForm.invalid
                    "
                  >
                    Salva
                  </button>
                </ng-container>
              </app-certificate-management>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-6">
        <mat-card appearance="outlined">
          <mat-card-title class="text-primary"> Dati Ordine </mat-card-title>

          <mat-card-content>
            <mat-list>
              <mat-list-item>
                <h4 class="font-weight-bold" mat-line>Numero Ordine</h4>
                <div mat-line>{{ voucher.orderNo }}</div>
              </mat-list-item>
              <mat-list-item class="border-top">
                <h4 class="font-weight-bold" mat-line>Data</h4>
                <div mat-line>{{ voucher.orderDate | date : "shortDate" }}</div>
              </mat-list-item>
              <mat-list-item class="border-top">
                <h4 class="font-weight-bold" mat-line>Sistema Provenienza</h4>
                <div mat-line>{{ voucher.orderSystem?.name || "-" }}</div>
              </mat-list-item>
              <mat-list-item class="border-top">
                <h4 class="font-weight-bold" mat-line>Auto Rinnovo</h4>
                <div mat-line>{{ voucher.autoRenew ? "Si" : "No" }}</div>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-6">
        <mat-card appearance="outlined">
          <mat-card-title class="text-primary">Dati Cliente</mat-card-title>

          <mat-card-content>
            <mat-list>
              <mat-list-item>
                <h4
                  class="font-weight-bold"
                  mat-line
                  *ngIf="voucher.customer.organization"
                >
                  Ragione Sociale
                </h4>
                <h4
                  class="font-weight-bold"
                  mat-line
                  *ngIf="!voucher.customer.organization"
                >
                  Nominativo
                </h4>
                <div mat-line>{{ voucher.customer.name }}</div>
              </mat-list-item>

              <mat-list-item class="border-top">
                <h4
                  class="font-weight-bold"
                  mat-line
                  *ngIf="voucher.customer.organization"
                >
                  Partita IVA
                </h4>
                <h4
                  class="font-weight-bold"
                  mat-line
                  *ngIf="!voucher.customer.organization"
                >
                  Codice Fiscale
                </h4>
                <div mat-line *ngIf="voucher.customer.organization">
                  {{ voucher.customer.vatNumber }}
                </div>
                <div mat-line *ngIf="!voucher.customer.organization">
                  {{ voucher.customer.fiscalCode }}
                </div>
              </mat-list-item>

              <mat-list-item class="border-top">
                <h4 class="font-weight-bold" mat-line>Indirizzo</h4>
                <div mat-line class="d-flex flex-wrap word-break-all">
                  <span [hidden]="!voucher.customer.address">
                    {{ voucher.customer.address }} -</span
                  >{{ voucher.customer.city }}
                  <span [hidden]="!voucher.customer.state"
                    >({{ voucher.customer.state }})</span
                  >
                </div>
              </mat-list-item>

              <mat-list-item class="border-top">
                <h4 class="font-weight-bold" mat-line>Sistema Provenienza</h4>
                <div mat-line>{{ voucher.customer.system?.name }}</div>
              </mat-list-item>

              <mat-list-item class="border-top">
                <h4 class="font-weight-bold" mat-line>ID Provenienza</h4>
                <div mat-line>{{ voucher.customer.extId }}</div>
              </mat-list-item>
            </mat-list>
            <div class="d-flex justify-content-end">
              <a
                mat-flat-button
                color="primary"
                *ngIf="customerExternalLink"
                type="button"
                [href]="customerExternalLink"
                target="_blank"
                >Apri Provenienza</a
              >
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-6">
        <mat-card appearance="outlined">
          <mat-card-title class="text-primary"> Dati Contatto </mat-card-title>

          <mat-card-content>
            <mat-list>
              <mat-list-item>
                <h4 class="font-weight-bold" mat-line>Referente Aruba</h4>
                <div mat-line>{{ voucher.internalContactName }}</div>
              </mat-list-item>

              <mat-list-item class="border-top">
                <h4 class="font-weight-bold" mat-line>Contatto Ref. Aruba</h4>
                <div
                  mat-line
                  *ngIf="
                    !voucher.internalContactEmail &&
                    !voucher.internalContactPhone
                  "
                >
                  -
                </div>
                <div
                  mat-line
                  *ngIf="voucher.internalContactEmail"
                  class="d-flex align-items-center"
                >
                  <a
                    [href]="'mailto:' + voucher.internalContactEmail"
                    class="text-primary"
                  >
                    <mat-icon color="primary">email</mat-icon>
                  </a>
                  <span class="ml-2 word-break-all">{{
                    voucher.internalContactEmail
                  }}</span>
                </div>
                <div
                  mat-line
                  *ngIf="voucher.internalContactPhone"
                  class="d-flex align-items-center"
                >
                  <mat-icon color="primary">phone</mat-icon>
                  <span class="ml-2 word-break-all">{{
                    voucher.internalContactPhone
                  }}</span>
                </div>
              </mat-list-item>

              <mat-list-item class="border-top">
                <h4 class="font-weight-bold" mat-line>Referente cliente</h4>
                <div mat-line>{{ voucher.contactName }}</div>
              </mat-list-item>

              <mat-list-item class="border-top">
                <h4 class="font-weight-bold" mat-line>Contatto Ref. Cliente</h4>
                <div mat-line class="d-flex align-items-center">
                  <a
                    [href]="'mailto:' + voucher.contactEmail"
                    class="text-primary"
                  >
                    <mat-icon color="primary">email</mat-icon>
                  </a>
                  <span class="ml-2 word-break-all">{{
                    voucher.contactEmail
                  }}</span>
                </div>
                <div mat-line class="d-flex align-items-center">
                  <mat-icon color="primary">phone</mat-icon>
                  <span class="ml-2 word-break-all">{{
                    voucher.contactPhone
                  }}</span>
                </div>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <!-- Firma di sigillo -->
    <div class="row mt-4">
      <div class="col-6" ngForm #automaticUserForm="ngForm">
        <mat-card appearance="outlined">
          <mat-card-title class="text-primary">
            Firma Di Sigillo Associata
          </mat-card-title>

          <mat-card-content *ngIf="automaticUser">
            <mat-list *ngIf="!editMode">
              <mat-list-item *ngIf="voucher?.automaticUser?.user">
                <h4 class="font-weight-bold" mat-line>Titolare</h4>
                <div mat-line>{{ voucher.automaticUser.user }}</div>
              </mat-list-item>

              <mat-list-item
                class="border-top"
                *ngIf="voucher?.automaticUser?.domain"
              >
                <h4 class="font-weight-bold" mat-line>Dominio Titolare</h4>
                <div mat-line>{{ voucher.automaticUser.domain }}</div>
              </mat-list-item>

              <mat-list-item
                class="border-top"
                *ngIf="voucher?.automaticUser?.delegatedUser"
              >
                <h4 class="font-weight-bold" mat-line>Delegato</h4>
                <div mat-line>{{ voucher.automaticUser.delegatedUser }}</div>
              </mat-list-item>

              <mat-list-item
                class="border-top"
                *ngIf="voucher?.automaticUser?.delegatedDomain"
              >
                <h4 class="font-weight-bold" mat-line>Dominio Delegato</h4>
                <div mat-line>{{ voucher.automaticUser.delegatedDomain }}</div>
              </mat-list-item>
            </mat-list>

            <ng-container *ngIf="editMode || addMode">
              <mat-form-field class="w-100">
                <mat-label>Titolare</mat-label>
                <input
                  matInput
                  type="text"
                  maxlength="255"
                  name="user"
                  placeholder="Inserisci Titolare"
                  [(ngModel)]="automaticUser.user"
                  #automaticUserModel="ngModel"
                  required
                />
                <mat-error
                  appFormFieldError
                  [formFieldModel]="automaticUserModel"
                  [form]="automaticUserForm"
                ></mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Dominio Titolare</mat-label>
                <input
                  matInput
                  type="text"
                  maxlength="255"
                  name="domain"
                  placeholder="Inserisci Dominio Titolare"
                  [(ngModel)]="automaticUser.domain"
                  #automaticDomainModel="ngModel"
                  required
                />
                <mat-error
                  appFormFieldError
                  [formFieldModel]="automaticDomainModel"
                  [form]="automaticUserForm"
                ></mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Delegato</mat-label>
                <input
                  matInput
                  type="text"
                  name="delegatedUser"
                  placeholder="Inserisci Delegato"
                  [(ngModel)]="automaticUser.delegatedUser"
                  #automaticDelegatedUserModel="ngModel"
                  required
                />
                <mat-error
                  appFormFieldError
                  [formFieldModel]="automaticDelegatedUserModel"
                  [form]="automaticUserForm"
                ></mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Dominio</mat-label>
                <input
                  matInput
                  type="text"
                  name="delegatedDomain"
                  placeholder="Inserisci Dominio Delegato"
                  [(ngModel)]="automaticUser.delegatedDomain"
                  #automaticDelegatedDomainModel="ngModel"
                  required
                />
                <mat-error
                  appFormFieldError
                  [formFieldModel]="automaticDelegatedDomainModel"
                  [form]="automaticUserForm"
                ></mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Password</mat-label>
                <input
                  matInput
                  type="password"
                  maxlength="255"
                  name="password"
                  placeholder="Inserisci Password Delegato"
                  [(ngModel)]="automaticUser.delegatedPassword"
                  #automaticDelegatedPasswordModel="ngModel"
                  required
                />
                <mat-error
                  appFormFieldError
                  [formFieldModel]="automaticDelegatedPasswordModel"
                  [form]="automaticUserForm"
                ></mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Pin</mat-label>
                <input
                  matInput
                  type="password"
                  maxlength="255"
                  name="pin"
                  placeholder="Inserisci Pin"
                  [(ngModel)]="automaticUser.pin"
                  #pinModel="ngModel"
                  required
                />
                <mat-error
                  appFormFieldError
                  [formFieldModel]="pinModel"
                  [form]="automaticUserForm"
                ></mat-error>
              </mat-form-field>
            </ng-container>
          </mat-card-content>

          <mat-card-actions align="end">
            <button
              mat-icon-button
              *ngIf="editMode"
              (click)="removeAutomaticUser()"
            >
              <mat-icon>delete</mat-icon>
            </button>

            <button
              mat-stroked-button
              color="primary"
              type="button"
              (click)="editAutomaticUser()"
              *ngIf="
                automaticUser &&
                !editMode &&
                !addMode &&
                authService.hasRole('AUTOMATIC_SIGNATURE_USER_CAN_WRITE')
              "
            >
              <mat-icon class="mr-2">edit</mat-icon>
              Modifica
            </button>

            <button
              mat-stroked-button
              color="primary"
              type="button"
              (click)="addAutomaticUser()"
              *ngIf="
                !automaticUser &&
                !addMode &&
                !editMode &&
                authService.hasRole('AUTOMATIC_SIGNATURE_USER_CAN_WRITE')
              "
            >
              <mat-icon class="mr-2">edit</mat-icon>
              Associa Firma Di Sigillo
            </button>

            <button
              mat-stroked-button
              color="primary"
              type="button"
              class="mr-2"
              *ngIf="editMode || addMode"
              (click)="
                automaticUserForm.reset(voucher.automaticUser);
                undoAutomaticUser()
              "
            >
              <mat-icon class="mr-2">close</mat-icon>
              Annulla
            </button>

            <button
              mat-flat-button
              color="primary"
              type="button"
              (click)="updateAutomaticUser()"
              *ngIf="
                (editMode || addMode) &&
                authService.hasRole('AUTOMATIC_SIGNATURE_USER_CAN_WRITE')
              "
              [disabled]="automaticUserForm.invalid"
            >
              <mat-icon class="mr-2">check</mat-icon>
              Salva
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
    <!-- / Dettagli -->

    <!-- Template licenze -->
    <div
      class="row mt-4"
      *ngIf="isReadonly || authService.hasRole('POLICY_VOUCHER_CAN_READ')"
    >
      <div class="col-24">
        <mat-card appearance="outlined">
          <mat-card-title class="text-primary">
            Dettaglio Template Licenza
          </mat-card-title>
          <mat-card-content>
            <div class="mt-4">
              <app-voucher-policies
                [voucher]="voucher"
                [readOnly]="isReadonly"
                (policySaved)="refreshVoucher()"
                (policyDeleted)="refreshVoucher()"
              >
              </app-voucher-policies>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <!-- / Template licenze -->

    <!-- Istanze Associate -->
    <div
      class="row mt-4"
      *ngIf="isReadonly || authService.hasRole('LICENSE_INSTANCE_CAN_READ')"
    >
      <div class="col-24">
        <mat-card appearance="outlined">
          <mat-card-title
            class="text-primary d-flex align-items-center justify-content-between"
          >
            <span class="d-flex align-items-center">
              <mat-icon class="mr-2">computer</mat-icon> Istanze Associate
            </span>
          </mat-card-title>
          <mat-card-content>
            <div class="mt-2">
              <app-voucher-license-instances
                [voucher]="voucher"
                [isReadonly]="isReadonly"
                #licenseInstances
                (licenceUploaded)="refreshVoucher()"
              >
              </app-voucher-license-instances>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <!-- / Istanze Associate -->

    <!-- Voucher Version -->
    <div
      class="row mt-4 mb-5"
      *ngIf="isReadonly || authService.hasRole('VERSION_CAN_WRITE')"
    >
      <div class="col-24">
        <app-voucher-version
          *ngIf="voucher"
          [voucher]="voucher"
          [isReadonly]="isReadonly"
        >
        </app-voucher-version>
      </div>
    </div>
    <!-- / Voucher Version -->
  </div>
</div>
