import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-voucher-public-home',
    templateUrl: './voucher-public-home.component.html',
    styleUrls: ['./voucher-public-home.component.scss'],
    standalone: false
})
export class VoucherPublicHomeComponent implements OnInit {
  @ViewChild(NgForm, { static: true }) form!: NgForm;

  voucherCode?: string;

  constructor(private router: Router) {}

  ngOnInit() {}

  goToVoucher() {
    if (this.form.valid) {
      this.router.navigate([`/public/voucher/${this.voucherCode}`]);
    }
  }
}
