import { Directive, Input } from '@angular/core';
import { FormValidators } from 'app/armp/shared/form-validators';
import { AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { CustomValidatorDirective } from 'app/armp/models/custom-validator';
import { UserService } from 'app/armp/services/user.service';

@Directive({
    selector: `[validatePassword][formControlName],[validatePassword][formControl],[validatePassword][ngModel]`,
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: PasswordValidatorDirective,
            multi: true,
        },
    ],
    standalone: false
})
export class PasswordValidatorDirective extends CustomValidatorDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('validatePassword') protected enabled: any;

  constructor(private userService: UserService) {
    super();
  }

  protected _validate(c: AbstractControl): ValidationErrors {
    return FormValidators.passwordValidator(c.value, this.userService);
  }
}
