<mat-card appearance="outlined">
  <mat-card-title class="text-primary">Stato voucher</mat-card-title>
  <mat-card-content>
    <div class="row mt-4 flex-wrap">

      <div class="col-24 col-sm-8 border-right">
        <div class="w-100 d-flex flex-column align-items-center">
          <h3 class="text-uppercase d-flex align-items-center">
            <mat-icon class="mr-2">event</mat-icon><strong>SCADENZA VOUCHER</strong>
          </h3>
          <p class="mat-headline-4 m-0">{{daysRemaining}}<sup class="mat-subtitle-2 ml-2">GG</sup></p>

          <app-alert class="mt-2"
            *ngIf="daysRemaining <= 0"
            type="warn"
            text="Il voucher è scaduto">
          </app-alert>

          <div class="mt-2 border-top w-100">
            <div class="d-flex align-items-center pt-2">
              <mat-form-field
                class="w-100 mr-3">
                <mat-label>Data scadenza</mat-label>
                <input matInput
                  [matDatepicker]="expiresOnPicker"
                  [(ngModel)]="voucher.expiresOn"
                  [readonly]="true"
                  (click)="expiresOnEditing && expiresOnPicker.open()">
                <mat-datepicker-toggle matSuffix
                  [for]="expiresOnPicker"
                  *ngIf="expiresOnEditing"></mat-datepicker-toggle>
                <mat-datepicker #expiresOnPicker></mat-datepicker>
              </mat-form-field>
              <button mat-icon-button
                (click)="startEditingExpiresOn()"
                *ngIf="!expiresOnEditing && !isReadonly && authService.hasRole('VOUCHER_CAN_WRITE')">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button
                (click)="saveExpiresOn()"
                *ngIf="expiresOnEditing && !isReadonly && authService.hasRole('VOUCHER_CAN_WRITE')">
                <mat-icon>done</mat-icon>
              </button>
              <button mat-icon-button
                (click)="undoExpiresOn()"
                *ngIf="expiresOnEditing && !isReadonly && authService.hasRole('VOUCHER_CAN_WRITE')">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>

          </div>
        </div>
      </div>

      <div *ngFor="let status of sortedStatuses; let first = first;"
        class="col text-center status-wrapper"
        [ngSwitch]="status.templateName"
        [ngStyle]="{width: colSize + '%'}">
        <h3 class="text-uppercase title"><strong>{{status.widgetTitle}}</strong></h3>
        <ng-container *ngSwitchCase="'BASE_CONSUMPTION'">
          <app-policy-base-consumption-status [status]="status"></app-policy-base-consumption-status>
        </ng-container>
        <ng-container *ngSwitchCase="'EXPIRATION'">
          <app-policy-expiration-status [status]="status"></app-policy-expiration-status>
        </ng-container>
      </div>
    </div>
  </mat-card-content>
</mat-card>