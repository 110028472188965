import { inject, Injectable, OnDestroy } from "@angular/core";

import { UserService } from "app/armp/services/user.service";
import { User } from "app/armp/models/user";
import {
  KeycloakEventType,
  KeycloakEventTypeLegacy,
  KeycloakService,
} from "keycloak-angular";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  user: User;
  private subscription: Subscription = new Subscription();
  private keycloak: KeycloakService = inject(KeycloakService);

  constructor(private userService: UserService) {
    this.updateToken();
  }

  get isLoggedIn(): boolean {
    return !!this.user;
  }

  getMe(): Promise<boolean | User> {
    return new Promise(async (resolve, reject) => {
      if (this.user) {
        resolve(this.user);
        return;
      }

      const token = await this.keycloak.getToken();
      const parsedToken = this.parseJwt(token) as any;

      // NOTE: we get the username from the parsed token since the this.keycloak.getUsername() is broken
      this.userService
        .getByUsername(parsedToken.preferred_username)
        .then((user: User) => {
          if (!user) {
            this.keycloak.logout();
            reject(false);
          }
          this.user = user;
          if (this.isUserAllowed(user)) {
            resolve(this.user);
          } else {
            reject(false);
          }
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  private updateToken() {
    const keycloakEvents$ = this.keycloak.keycloakEvents$.subscribe({
      next(event) {
        if (event.type == KeycloakEventTypeLegacy.OnTokenExpired) {
          this.keycloak.updateToken();
        }
      },
    });

    this.subscription.add(keycloakEvents$);
  }

  async logout() {
    this.user = undefined;
    await this.keycloak.logout();
  }

  hasRole(role: string, user?: User): boolean {
    return this.hasRoles([role], user);
  }

  hasRoles(roles: Array<string>, user: User = this.user): boolean {
    if (!user) {
      return false;
    }

    let result = true;

    roles.forEach((r) => {
      result = result && user.roles.indexOf(r) !== -1;
    });

    return result;
  }

  private isUserAllowed(user: User) {
    return this.hasRoles(["UI"], user);
  }

  private parseJwt(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
