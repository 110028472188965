<h2 mat-dialog-title>{{ dialogData.title | translate }}</h2>
<mat-dialog-content>
  <span
    *ngIf="dialogData.message"
    class="message"
    [innerHTML]="dialogData.message | translate"
  >
  </span>

  <form *ngIf="form" [formGroup]="form" class="mt-2">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ dialogData.prompt?.label ?? "" | translate }}</mat-label>
      <input
        matInput
        type="text"
        [placeholder]="dialogData.prompt?.placeholder ?? '' | translate"
        formControlName="value"
      />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="row mt-1 justify-content-end">
  <button mat-flat-button (click)="onCancel()" *ngIf="dialogData.cancelText">
    {{ dialogData.cancelText | translate }}
  </button>

  <button
    mat-flat-button
    [disabled]="!!form && !form.valid"
    (click)="onOk()"
    class="ml-3"
    [color]="okButtonType"
    *ngIf="dialogData.okText"
  >
    {{ dialogData.okText | translate }}
  </button>
</mat-dialog-actions>
