import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PolicyManagementComponent} from './policy-management/policy-management/policy-management.component';
import {PolicyEditModalComponent} from './policy-edit-modal/policy-edit-modal.component';
import {PolicyCfgComponent} from './policy-cfg/policy-cfg.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {FormValidatorModule} from '../shared/form-validator/form-validator.module';


@NgModule({
    declarations: [
        PolicyManagementComponent,
        PolicyEditModalComponent,
        PolicyCfgComponent,

    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        FormsModule,
        SharedModule,
        FormValidatorModule
    ],
    exports: [
        PolicyManagementComponent,
        PolicyEditModalComponent,
        PolicyCfgComponent,

    ]
})
export class PolicyModule {
}
