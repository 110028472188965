<div class="row align-items-center">
  <div class="col-24 col-sm-10">
    <strong>ID Prodotto:</strong> {{ voucher.product.slug }} |
    {{ voucher.product.name }}
  </div>
  <div class="col-24 col-sm-8 text-center">
    <strong>Template Licenza:</strong>
    {{ voucher.product.licenseTemplate.name }}
  </div>
  <div class="col-24 col-sm-6 text-right">
    <button
      mat-flat-button
      type="button"
      color="primary"
      *ngIf="!readOnly && authService.hasRole('VOUCHER_CAN_WRITE')"
      (click)="addPolicy()"
    >
      Aggiungi Policy
    </button>
  </div>
</div>

<table mat-table [dataSource]="policies" class="mat-elevation-z0 w-100">
  <!-- Policy -->
  <ng-container matColumnDef="policy">
    <th mat-header-cell *matHeaderCellDef>Policy</th>
    <td mat-cell *matCellDef="let policy" ngForm #form="ngForm">
      <ng-container *ngIf="!isEditingPolicy(policy)"
        >{{ policy.name }}
      </ng-container>
      <ng-container *ngIf="isEditingPolicy(policy)">
        <mat-form-field>
          <mat-label>Nome</mat-label>
          <input
            matInput
            [(ngModel)]="policy.name"
            name="name"
            #policyNameModel="ngModel"
            type="text"
            maxlength="255"
            placeholder="Nome"
            required
          />
          <mat-hint align="end">{{ policy?.name?.length || 0 }}/255</mat-hint>
          <mat-error
            appFormFieldError
            [formFieldModel]="policyNameModel"
            [form]="form"
          ></mat-error>
        </mat-form-field>
      </ng-container>
    </td>
  </ng-container>

  <!-- Tipo -->
  <ng-container matColumnDef="tipo">
    <th mat-header-cell *matHeaderCellDef>Tipo</th>
    <td mat-cell *matCellDef="let policy">
      {{ policy.implementation.name }}
    </td>
  </ng-container>

  <!-- Indicatori -->
  <ng-container matColumnDef="indicatori">
    <th mat-header-cell *matHeaderCellDef>Indicatori</th>
    <td mat-cell *matCellDef="let policy; let i = index" ngForm #form="ngForm">
      <div class="d-flex items-center justify-content-between">
        <div class="pb-2 w-100 flex-grow-1">
          <app-policy-cfg
            *ngIf="policy.implementation"
            [(ngModel)]="policies[i]"
            [editMode]="isEditingPolicy(policy)"
            [viewMode]="'inline'"
            [showActual]="true"
            [topForm]="form"
            size="sm"
            name="config"
          ></app-policy-cfg>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <button
            mat-icon-button
            type="button"
            color="accent"
            (click)="startEditPolicy(policy)"
            *ngIf="canEditPolicy(policy) && !isEditingPolicy(policy)"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <button
            mat-icon-button
            type="button"
            color="accent"
            [disabled]="form.invalid"
            (click)="savePolicy(policy, form)"
            *ngIf="!readOnly && isEditingPolicy(policy)"
          >
            <mat-icon>check</mat-icon>
          </button>

          <button
            mat-icon-button
            type="button"
            color="accent"
            (click)="undoPolicyEdit(policy)"
            *ngIf="isEditingPolicy(policy)"
          >
            <mat-icon>cancel</mat-icon>
          </button>

          <button
            mat-icon-button
            type="button"
            color="accent"
            (click)="deletePolicy(policy)"
            *ngIf="canDeletePolicy(policy)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
