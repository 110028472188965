import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app/armp/core/auth.service';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    standalone: false
})
export class SearchBarComponent implements OnInit {
  public searchText!: string;
  public selectedSearchType;
  private _searchTypes = [
    {
      name: 'voucher',
      label: 'Voucher',
      placeholder: 'per codice, cliente, prodotto, ...',
      roles: ['VOUCHER_CAN_READ'],
    },
    {
      name: 'customer',
      label: 'Clienti',
      placeholder: 'per nome, partita IVA, ...',
      roles: ['CUSTOMER_CAN_READ'],
    },
    {
      name: 'product',
      label: 'Prodotti',
      placeholder: 'per codice, nome, ...',
      roles: ['PRODUCT_CAN_READ'],
    },
  ];

  @ViewChild('searchForm', { static: true }) private searchForm!: NgForm;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {
    this.selectedSearchType = this.searchTypes[0];
  }

  search() {
    const queryParams = {
      searchText: this.searchText,
    };

    if (this.searchForm.valid) {
      const route = this.selectedSearchType.name;

      this.router.navigate([route], {
        queryParams,
      });
    }
  }

  get searchTypes() {
    return this._searchTypes?.filter((t) => this.authService.hasRoles(t.roles)) || [];
  }
}
