import {Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild,} from '@angular/core';
import {Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';

import {DataTableColumn, DataTableOrder, DataTableOrderDir, DataTableRequest,} from 'app/armp/models/datatable-request';
import {Page} from 'app/armp/models/page';
import {CrudService} from 'app/armp/crud/services/crud-service';
import {IDynamicTableColumnData} from 'common/interfaces/dynamic-column.interface';
import {LoaderService} from 'app/armp/core/loader.service';

@Component({
    selector: 'app-dynamic-table',
    templateUrl: './dynamic-table.component.html',
    styleUrls: ['./dynamic-table.component.scss'],
    standalone: false
})
export class DynamicTableComponent implements OnInit {
    @Input() tableClass: string;
    @Input() paginatorClass: string;

    @Input()
    set columns(val: IDynamicTableColumnData[]) {
        this._columns = val;
        this.updateCols();
    }

    @Input()
    set showDetailLink(val: boolean) {
        this._showDetailLink = val;
        this.updateCols();
    }

    get showDetailLink() {
        return this._showDetailLink;
    }

    @Input()
    set searchText(val: string) {
        this._searchText = val;
        this.resetPagination();
        this.fetchPage();
    }

    get searchText() {
        return this._searchText;
    }

    @Input() sorts: Array<any>;
    @Input() trackBy = 'id';
    @Input() showDeleteBtn: string | boolean = true;
    @Input() extraButtons = 0;
    @Input() service: CrudService<any>;
    @Input() gridService: CrudService<any>;
    @Input() detailRoute: string;

    @Output() canDelete: EventEmitter<CanDeleteEvent> = new EventEmitter();
    // NOTE this is used to do a custom get, because the default getGrid is hardcoded in this component
    @Output() fetchData: EventEmitter<any> = new EventEmitter();
    @Output() dataFetched: EventEmitter<Page<any>> = new EventEmitter();

    @ContentChild('extraButtonsTmpl') extraButtonsTmpl: TemplateRef<any>;

    @ViewChild('detailLinkHeaderTmpl', {static: true})
    detailLinkHeaderTmpl: TemplateRef<any>;
    @ViewChild('detailLinkCellTmpl', {static: true})
    detailLinkCellTmpl: TemplateRef<any>;

    cols: IDynamicTableColumnData[] = [];
    rows: any[] = [];
    sort: Sort;
    pagination: PageEvent = {
        pageIndex: 0,
        pageSize: 10,
        length: 0,
    };
    // totalElements = 0;
    // pageNum = 0;
    dataTableRequest: DataTableRequest = new DataTableRequest();

    fetchInitialized = false;
    isLoading = false;
    isDisabled = false;

    private _columns: any[] = [];
    private _showDetailLink = true;
    private _searchText: string = undefined;

    constructor(private _router: Router, private loaderService: LoaderService) {
    }

    ngOnInit() {
        if (!this.fetchInitialized) {
            this.fetchPage();
        }
    }

    get columnIdentifiers(): string[] {
        return this.cols.map((column) => column.prop || 'links');
    }

    updateCols() {
        this.cols = [];
        this.cols = this.cols.concat(this._columns);
        this.cols.forEach((c) => {
            if (!('sortable' in c)) {
                c.sortable = true;
            }
        });

        let linkCount = +this.showDetailLink;

        if (this.rows) {
            linkCount += +(
                this.rows.filter((r) => this._showDeleteBtn(r)).length > 0
            );
            linkCount += this.extraButtons;
        }

        if (linkCount) {
            this.cols.push({
                name: '',
                prop: 'links',
                sortable: false,
                cellTemplate: this.detailLinkCellTmpl,
                headerTemplate: this.detailLinkHeaderTmpl,
                styleHeader: {width: '1px'},
            });
        }
    }

    onPageChange(event: PageEvent) {
        this.pagination.pageSize = event.pageSize;
        this.pagination.pageIndex = event.pageIndex;

        this.dataTableRequest.length = this.pagination.pageSize;
        this.dataTableRequest.start =
            this.pagination.pageIndex * this.pagination.pageSize;

        this.fetchPage();
    }

    detailUrl(): string {
        if (this.detailRoute) {
            return this.detailRoute;
        } else {
            return this._router.url.split('?')[0];
        }
    }

    fetchPage() {
        this.fetchInitialized = true;
        this.dataTableRequest.columns = [];
        this._columns.forEach((c) => {
            const dc = new DataTableColumn();
            dc.data = c.sortProp || c.prop;
            dc.name = c.prop;
            dc.orderable = c.sortable;

            if ('searchable' in c) {
                dc.searchable = c.searchable;
            } else {
                dc.searchable = true;
            }
            this.dataTableRequest.columns.push(dc);
        });

        const requestOrder: DataTableOrder = new DataTableOrder();
        if (!this.sort) {
            if (!this.sorts) {
                // NOTE: set the first column as default sorting col
                this.sorts = [
                    {prop: this._columns[0].prop, dir: DataTableOrderDir.Asc},
                ];
            }

            this.sort = {active: this.sorts[0].prop, direction: this.sorts[0].dir};
        }
        if (this.sort) {
            requestOrder.column = this.cols.findIndex(
                (c) => c.prop === this.sort.active
            );
            requestOrder.dir = this.sort.direction;
        }
        this.dataTableRequest.order = [requestOrder];
        this.dataTableRequest.search.value = this.searchText || '';
        this.dataTableRequest.search.value =
            this.dataTableRequest.search.value.trim();

        this.isLoading = true;
        this.isDisabled = true;

        let promise: Promise<Page<any>>;

        if (this.service) {
            promise = this.service.getGrid(this.dataTableRequest);
        } else {
            const event = {
                dataTableRequest: this.dataTableRequest,
                promise: null,
            };
            // NOTE this is used to do a custom get, because the default getGrid is hardcoded in this component
            this.fetchData.emit(event);
            promise = event.promise;
            if (!promise) {
                throw new Error('fetchData must set event.promise!');
            }
        }

        this.loaderService.show();
        promise
            .then((page) => {
                this.loaderService.hide();
                this.pagination.pageIndex = page.number;
                this.pagination.length = page.totalElements;

                const start = page.number * this.dataTableRequest.length;
                const tmpRows = [];

                for (let i = 0; i < page.content.length; i++) {
                    tmpRows[start + i] = page.content[i];
                }

                this.rows = page.content;

                if (page.number === 0 && page.numberOfElements === 0) {
                    this.rows = [];
                }

                this.isLoading = false;
                this.isDisabled = false;

                this.updateCols();

                this.dataFetched.emit(page);
            })
            .catch((err) => {
                this.loaderService.hide();
                this.isLoading = false;
                this.isDisabled = false;
            });
    }

    onSort(event: Sort) {
        this.isLoading = true;
        this.isDisabled = true;
        if (!event.direction || (event.direction as any) === '') {
            event.direction = 'asc';
        }
        this.sort = event;
        this.fetchPage();
    }

    deleteRow(row: any) {
        if (confirm(`Rimuovere l'elemento selezionato?`)) {
            const pageIndex =
                this.pagination.pageIndex > 0 && this.rows.length - 1 === 0
                    ? this.pagination.pageIndex - 1
                    : this.pagination.pageIndex;

            if (this.service) {
                this.service.delete(row.id).then(() => {
                    this.resetPagination({...this.pagination, pageIndex});
                    this.fetchPage();
                });
            } else {
                this.gridService.delete(row.id).then(() => {
                    this.resetPagination({...this.pagination, pageIndex});
                    this.fetchPage();
                });
            }
        }
    }

    _showDeleteBtn(row): boolean {
        if (this.showDeleteBtn === 'custom') {
            const event = new CanDeleteEvent();
            event.row = row;
            this.canDelete.emit(event);
            return event.canDelete;
        } else {
            return !!this.showDeleteBtn;
        }
    }

    private resetPagination(
        pagination: PageEvent = {
            pageIndex: 0,
            pageSize: 10,
            length: 0,
        }
    ) {
        this.pagination = pagination;
        this.dataTableRequest = new DataTableRequest();
        this.dataTableRequest.length = this.pagination.pageSize;
        this.dataTableRequest.start =
            this.pagination.pageIndex * this.pagination.pageSize;
    }
}

export class CanDeleteEvent {
    row: any;
    canDelete: boolean;
}
