import {Component, Input, OnInit} from '@angular/core';

import {VoucherService} from 'app/armp/services/voucher.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from 'app/armp/core/auth.service';
import {ToastService} from 'app/armp/services/toast.service';
import {dateDifference} from 'app/armp/core/utils/date.utils';
import {LoaderService} from 'app/armp/core/loader.service';
import {CaptchaService} from 'app/armp/core/captcha.service';

@Component({
    selector: 'app-voucher-status',
    templateUrl: './voucher-status.component.html',
    styleUrls: ['./voucher-status.component.scss'],
    standalone: false
})
export class VoucherStatusComponent implements OnInit {
    @Input() voucher: any;
    @Input() useToken = false;

    private _isReadonly = false;
    @Input()
    set isReadonly(v: boolean) {
        this._isReadonly = v;
    }

    get isReadonly() {
        return this._isReadonly;
    }

    status: Array<any> = [];

    expiresOnCurrentValue?: boolean;
    expiresOnEditing?: boolean;

    constructor(
        private voucherService: VoucherService,
        private toastService: ToastService,
        private translateService: TranslateService,
        public authService: AuthService,
        private loaderService: LoaderService,
        private captchaService: CaptchaService
    ) {
    }

    ngOnInit() {
        this.refresh();
    }

    async refresh() {
        let headers;
        if (this.useToken) {
            headers = await this.captchaService.getCaptchaHeader();
        }
        this.voucherService
            .getStatus(this.voucher.id, this.voucher.code, headers)
            .then((status) => {
                this.status = this.sortStatus(status);
            });
    }

    get colSize() {
        let totalWidgets = this.status.length + (this.voucher.expiresOn ? 1 : 0);
        return 100 / totalWidgets;
    }

    get sortedStatuses() {
        return this.status;
    }

    get daysRemaining() {
        if (this.voucher.expiresOn) {
            return dateDifference(new Date(this.voucher.expiresOn), new Date());
        }
    }

    sortStatus(status: Array<any>) {
        return status.sort((a, b) => {
            if (a.widgetName === b.widgetName) {
                return 0;
            }

            if (a.widgetName === 'LICENSE_INSTANCE') {
                return -1;
            }
            if (a.widgetName < b.widgetName) {
                return -1;
            }
            return 1;
        });
    }

    startEditingExpiresOn() {
        this.expiresOnCurrentValue = this.voucher.expiresOn;
        this.expiresOnEditing = true;
    }

    stopEditingExpiresOn() {
        this.expiresOnCurrentValue = undefined;
        this.expiresOnEditing = false;
    }

    saveExpiresOn() {
        this.voucherService.save(this.voucher).then(() => {
            this.stopEditingExpiresOn();
            this.toastService.success({
                message: this.translateService.instant('TOASTR.SAVE_SUCCESS'),
            });
        });
    }

    undoExpiresOn() {
        this.voucher.expiresOn = this.expiresOnCurrentValue;
        this.expiresOnCurrentValue = undefined;
        this.stopEditingExpiresOn();
    }
}
