import {Location} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
    standalone: false
})
export class PageHeaderComponent implements OnInit {
    @Input() title: string = '';
    @Input() showBack = true;
    @Input() backUrl: string[] = [];

    @Input() breadcrumb: string[] = [];

    constructor(private location: Location,
                private router: Router) {
    }

    ngOnInit(): void {
    }

    onGoBack() {
        if (this.backUrl.length) {
            this.router.navigate(this.backUrl);
        } else {
            this.location.back();
        }
    }
}
