import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    standalone: false
})
export class AlertComponent implements OnInit {
  @Input() type: 'success' | 'error' | 'warn' | 'info' = 'info';
  @Input() text: string;

  constructor() {}

  ngOnInit(): void {}
}
