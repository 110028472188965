import {Component, OnInit} from '@angular/core';
import {LoaderService} from 'app/armp/core/loader.service';
import {ELoaderActionEnum} from 'common/enums/loader.enum';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: false
})
export class LoaderComponent implements OnInit {
    canShowLoader = false;
    loaderData: any;

    constructor(private loaderService: LoaderService) {
    }

    ngOnInit() {
        this.loaderService.onLoaderChange.subscribe((loaderAction: any) => {
            switch (loaderAction.action) {
                case ELoaderActionEnum.SHOW:
                    this.loaderData = loaderAction.data;
                    this.show();
                    this.loaderService.isLoading = true;
                    break;
                case ELoaderActionEnum.HIDE:
                    this.loaderData = undefined;
                    this.hide();
                    this.loaderService.isLoading = false;
                    break;
            }
        });
    }

    show() {
        setTimeout(() => (this.canShowLoader = true));
    }

    hide() {
        setTimeout(() => (this.canShowLoader = false));
    }
}
