import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPrivateCrudService } from 'app/armp/crud/services';

@Injectable()
export class CustomerService extends ApiPrivateCrudService<object> {
  resourceName(): string {
    return 'customer';
  }

  search(searchText: string) {
    let params = new HttpParams();
    params = params.set('searchText', searchText);
    return this.httpHandler.httpPostUrlEncoded('/search', params);
  }

  getExternalLink(id: number) {
    return this.httpHandler.httpGet(`/${id}/external-link`);
  }

  saveCertificateBindings(customerId: number, certificates: any) {
    certificates = certificates.filter((c: any) => {
      delete c.__cid;
      return true;
    });

    let data = new HttpParams();
    data = data.set('certificates', JSON.stringify(certificates));

    return this.httpHandler.httpPost('/certificate_bindings/' + customerId, data);
  }

  countByCustomerId(customerId: number) {
    return this.httpHandler.httpGet('/' + customerId + '/vouchers');
  }
}
