import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private snackBar: MatSnackBar) {}

  open({ message, action, config }: { message?: string; action?: string; config?: MatSnackBarConfig }) {
    config = config || {};

    // Default settings
    config.horizontalPosition = config.horizontalPosition || 'start';
    config.verticalPosition = config.verticalPosition || 'bottom';
    config.duration = config.duration || 4000;

    this.snackBar.open(message ?? '', action, config);
  }

  success({ message, action, config }: { message?: string; action?: string; config?: MatSnackBarConfig }) {
    this.open({ message, action, config: { ...config, panelClass: 'mat-snackbar-success' } });
  }

  error({ message, action, config }: { message?: string; action?: string; config?: MatSnackBarConfig }) {
    this.open({ message, action, config: { ...config, panelClass: 'mat-snackbar-error' } });
  }
}
